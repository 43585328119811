import React from 'react';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';

import Image from '../Image';
import styles from './InlineImage.module.scss';

const InlineImage = ({
    image = null,
    text = '',
    size = '',
    theme = '',
    ratio = { width: '', height: '' },
}) => {
    const hasRatio = !!ratio.width || !!ratio.height;

    const aspectRatio = hasRatio
        ? `${ratio.width}/${ratio.height}`
        : `${image.width}/${image.height}`;

    const imageClasses = classNames(
        styles['InlineImage'],
        styles['InlineImage--' + ucFirst(size)],
        styles['InlineImage--' + ucFirst(theme)]
    );

    return (
        <div className={imageClasses}>
            <div className={styles['InlineImage__Container']}>
                <figure className={styles['InlineImage__Figure']}>
                    <div
                        className={styles['InlineImage__Image']}
                        style={{
                            aspectRatio: aspectRatio,
                        }}>
                        <Image
                            {...{ image }}
                            sizes={'(min-width: 1800px) 1920px'}
                        />
                    </div>
                    {!!text && (
                        <figcaption
                            className={styles['InlineImage__Text']}
                            dangerouslySetInnerHTML={{
                                __html: text,
                            }}></figcaption>
                    )}
                </figure>
            </div>
        </div>
    );
};

InlineImage.propTypes = {};

export default InlineImage;
